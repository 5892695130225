@font-face {
  font-family: 'OpenSans';
  src: local("OpenSans"), url("./fonts/open-sans/OpenSans-Regular.ttf") format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  input, textarea {
    @apply border border-background-secondary-alt shadow-sm rounded-lg text-sm focus:z-10 focus:border-secondary focus:outline-none
    focus:ring-secondary focus:ring-2 disabled:opacity-50 px-3 py-2 bg-background-secondary text-txt-secondary
  }
}

body {
  margin: 0;
}

code {
  font-family: OpenSans
  monospace;
}

#page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#navbar {
  min-height: 50px;
}

#footer {
  min-height: 50px;
}

#content {
  flex: 1;
}

.has-pointer-on-hover:hover {
  cursor: pointer;
}

.anchor-like-button {
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
}

table {
  @apply divide-y divide-gray-200
}

thead > tr > th {
  @apply px-6 py-3 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase w-3/4
}

tbody {
  @apply bg-white divide-y divide-gray-200
}

tbody > tr {
  @apply bg-gray-200
}

tbody > tr > td {
  @apply px-6 py-4 whitespace-nowrap
}
